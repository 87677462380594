body {
  background-color: #008083;
  height: 100%;
}

/*################################ PANEL STLYES ################################*/

.panel {
  position: absolute;
  top: 16px;
  left: 100px;
  width: 60%;
  height: 80%;
  user-select: none;
  z-index: 1;
  background-color: white;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
.resize {
  background-color: silver;
}
.content-wrapper {
  background-color: black;
  height: calc(100% - 38px);
}
.content {
  max-width: 100%;
  border: none;
}

textarea {
  box-sizing: border-box;
  height: 90%;
  resize: none;
  border: none;
  outline: none;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.corner {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: none;
}

.top-left {
  top: 0;
  left: 0;
  cursor: nw-resize;
}

.top-right {
  top: 0;
  right: 0;
  cursor: ne-resize;
}

.bottom-left {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
}

.bottom-right {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.handle-top {
  position: absolute;
  height: 3px;
  width: 100%;
  top: 0;
  left: 0;
  cursor: n-resize;
}
.handle-bottom {
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  cursor: s-resize;
}
.handle-left {
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  left: 0;
  cursor: w-resize;
}
.handle-right {
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  right: 0;
  cursor: e-resize;
}

.header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 50%,
    rgba(16, 123, 202, 1) 100%
  );
  border-bottom: 3px silver;
}
.header-decoration {
  user-select: none;
}

.header-text {
  color: aliceblue;
  padding: 8px 0 5px 5px;
  font-size: 10px;
}
.header-icon {
  padding: 8px 0 5px 10px;
  width: 10px;
  height: 10px;
}
.button-container {
  margin-left: auto;
  padding-top: 5px;
  padding-right: 5px;
}

.header-buttons {
  width: 13px;
  height: 13px;
  margin-top: 4px;
  margin-left: 1px;
  text-decoration: none;
  border-radius: 0;
  background-color: silver;
}
#minimize {
  width: 8px;
  height: 8px;
  margin-bottom: 3px;
}
.menubar {
  margin-top: 15px;
  width: 100%;
  height: 20px;
  background-color: silver;
}
.menu-items {
  padding-top: 1px;
  margin-left: 2px;
}
.menu-button {
  height: 12px;
  margin: 0px 2px 1px 2px;
  padding: 0 2px;
  margin-top: 5px;
  font-size: 8px;
  border-radius: 0;
  background: silver;
  border: 1px solid transparent;
}
.menu-button:hover {
  border: 1px solid black;
}
.content {
  position: relative;
  height: calc(100.4%);
  width: calc(100.61%);
}

/*################################TASK BAR STLYES /*################################*/

.task-bar {
  bottom: 0;
  position: fixed;
  width: 100%;
  display: flex;
  list-style-type: none;
  background-color: silver;
  overflow: hidden;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
.task-bar button {
  text-decoration: none;
  color: black;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
#start-button {
  padding: 2px 5px 2px 5px;
  margin: 2px;
  text-decoration: none;
  border-radius: 0;
  background-color: silver;
}
.start-icon {
  width: 15px;
  height: 15px;
  margin: 0px 2px 0px 0px;
}
.divider-long,
.divider-fat {
  background-color: silver;
  margin: 3px 1px 3px 1px;
  padding: 0 0.5px 0 0.5px;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
.divider-fat {
  margin: 4px 1px;
  margin-right: 2px;
  padding: 0 1px 0 1px;
}
.divider-long:after {
  content: "\200b";
}
.taskbar-element {
  position: relative;
  text-decoration: none;
  padding: 2px;
  margin: 3px 1px 0px 1px;
  border-radius: 0;
  background-color: silver;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 2 auto;
  max-width: 170px;
}
.element-text {
  text-overflow: ellipsis;
}
.tray {
  align-items: center;
  display: inline-flex;
  padding: 2px;
  margin: 3px;
  background-color: silver;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
.right-taskbar {
  margin-left: auto;
  order: 3;
  white-space: nowrap;
}
.desktop-container {
  top: 0;
  bottom: 0;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 30px);
}
.icon {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 64px;
  margin: 20px;
  text-align: center;
}
.icon-img {
  width: 50px;
  height: 50px;
}
.icon-name {
  margin-top: 3px;
  font-size: 12px;
  display: block;
  width: 50px;
  word-wrap: break-word;
  background-color: #008083;
}
.tab-container {
  margin: 0px 2px;
  margin-bottom: 3px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: silver;
  min-width: 0;
  flex-shrink: 2;
  border-top: 1px solid #dfdfdf;
}
.icon-anchor {
  text-decoration: none;
  color: black;
}
